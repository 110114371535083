import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface TabGroup {
  activeTabId: string;
}

interface TabManagerContextValue {
  tabGroups?: Record<string, TabGroup>;
  updateActiveTab: (prefix: string, activeTabId: string) => void;
}

const TabManagerContext = createContext<TabManagerContextValue>({} as TabManagerContextValue);

/**
 * The TabManagerContext is expected to be used to manage the active tabs for different tab "groups".
 * A tab group can be defined as a TabsComponent which contains multiple tabs. A TabsComponent has
 * a prefix - which identifies the group as a whole.
 *
 * This context will store an object whose keys are the prefixes, and the value is an object
 * containing the activeTabId.
 */
export const TabManagerContextProvider: React.FC = ({ children }) => {
  const [tabGroups, setTabGroups] = useState<Record<string, TabGroup>>();

  const updateActiveTab = useCallback(
    (prefix: string, activeTabId: string) => {
      setTabGroups((tabGroups) => ({ ...tabGroups, [prefix]: { activeTabId } }));
    },
    [setTabGroups],
  );

  const value = useMemo(() => ({ tabGroups, updateActiveTab }), [tabGroups, updateActiveTab]);

  return <TabManagerContext.Provider value={value}>{children}</TabManagerContext.Provider>;
};

export const useTabManagerContext = () => {
  return useContext(TabManagerContext);
};

/**
 * Custom hook to manage a tab group.
 * @param {string} prefix - The prefix for identifying the tab group.
 * @returns An object containing the active tab ID and a function to update the active tab for the group.
 */
export const useTabGroup = (prefix: string) => {
  const context = useTabManagerContext();

  const updateActiveTab = useCallback(
    (tabId: string) => context.updateActiveTab(prefix, tabId),
    [context, prefix],
  );

  return {
    activeTabId: context.tabGroups?.[prefix]?.activeTabId,
    updateActiveTab,
  };
};
